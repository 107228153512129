<template>
  <div>
    <el-form style="padding: 20px 20px 0" :model="form" ref="form" inline>
      <el-form-item label="系列名称">
        <el-input v-model="form.collection_name"></el-input>
      </el-form-item>
      <el-form-item label="作者UCID">
        <el-input v-model="form.artist_ucid"></el-input>
      </el-form-item>
      <el-form-item label="作者昵称">
        <el-input v-model="form.artist_name"></el-input>
      </el-form-item>
      <el-form-item label="系列状态">
        <el-select v-model="form.status">
          <el-option label="全部" value=""> </el-option>
          <el-option label="已启用" value="release"> </el-option>
          <el-option label="未启用" value="unrelease"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button type="info" @click="onClear">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="padding: 0 20px;">
      <el-table :data="list" border stripe row-key="ucid" @sort-change="handleSortChange">
        <!-- sortable="custom" -->
        <el-table-column label="系列名称" prop="collection_name"> </el-table-column>
        <el-table-column label="系列主图">
          <template slot-scope="scope">
            <img :src="scope.row.cover" width="80" />
          </template>
        </el-table-column>
        <el-table-column label="作者信息" prop="artist_ucid"
          ><template slot-scope="scope">
            {{ scope.row.artist_ucid + `(${scope.row.artist_name})` }}
          </template></el-table-column
        >
        <el-table-column label="创建时间" prop="created_at_sec" sortable="custom">
          <template slot-scope="scope">
          <div>{{ scope.row.created_at_sec | formatDate }}</div>
        </template>
        </el-table-column>

        <el-table-column label="作品总数" prop="total_work_num"></el-table-column>
        <el-table-column label="恒境作品数" prop="forever_realm_only_num"></el-table-column>
        <el-table-column label="小店作品数" prop="shop_work_num"></el-table-column>
        <el-table-column label="标签数" prop="tag_num"></el-table-column>
        <el-table-column label="系列状态" prop="status">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 'release'" type="success">已启用</el-tag>
            <el-tag v-if="scope.row.status === 'unrelease'" type="info">未启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="收藏数量" prop="bookmark_num" sortable="custom"></el-table-column>
        <el-table-column label="操作" width="250" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleH5(scope.row)">
              预览系列
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getCollectionList } from "@/api/nft.js";
import dayjs from "dayjs";
import config from "@/configs";
export default {
  components: {},
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      form: {
        status: "",
      },
      list: [],
      // 查询参数
      ucid: "",
      mobile: "",
      email: "",
      sortBy: "",
      sortOrder: "",
    };
  },
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    formatMoney: function(value) {
      return value ? `+${value}` : "";
    },
  },
  mounted() {
    this.fetchList();
  },
  computed: {
    params() {
      const { collection_name, artist_ucid, artist_name, status } = this.form;
      return {
        collection_name: collection_name,
        artist_ucid: artist_ucid,
        artist_name: artist_name,
        status: status,
        page: this.page,
        page_size: this.pageSize,
        sort_by: this.sortBy,
        ordre_by: this.sortOrder,
      };
    },
  },
  methods: {
    onQuery() {
      this.page = 1;
      this.fetchList();
    },
    onClear() {
      this.page = 1;
      this.form = {
        collection_name: "",
        artist_ucid: "",
        artist_name: "",
        status: "",
      };
      this.fetchList();
    },
    async fetchList() {
      try {
        const params = { ...this.params };
        const { data } = await getCollectionList(params);
        this.list = data.data.items || [];
        this.total = data.data.total || 0;
      } catch (error) {
        this.list = [];
        this.total = 0;
      }
    },
    handleClickSearch() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchData();
    },

    handleClickReset() {
      this.ucid = "";
      this.mobile = "";
      this.email = "";
      this.list = [];
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSortChange(e) {
      const { prop, order } = e;
      this.sortBy = prop;
      this.sortOrder = order === "ascending" ? "asc" : "desc";
      this.fetchList();
    },
    handleH5(row) {
      // todo 小店后台
      // window.open(`${config.nftLiteUrl}/nft?txid=${row.txid}`);
    },
  },
};
</script>
